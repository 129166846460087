
import {Component, Vue, Prop} from 'vue-property-decorator';
import {ROUTES} from "@/data";
import {Action, Mutation} from "vuex-class";
import {StoreAction, StoreMutation} from "@/types";
import {isValidEmail, isValidPhone, passwordStrength} from "@/helpers/commons";

@Component({
    name: "Invitation"
})
export default class Invitation extends Vue {
    errors: any = {};
    globalErrors: any = {};
    loading = false;

    tmpUser = {
        password: '',
        confirmPassword: ''
    }

    passwordStrength = passwordStrength;

    @Action('actions/registerInvitation') registerInvitation!: StoreAction;

    @Mutation('auth/setToken') setToken!: StoreMutation;
    @Mutation('auth/setLoginVerified') setLoginVerified!: StoreMutation;

    get token() {
        return this.$route.params.token;
    }

    get username() {
        return this.$route.query.username;
    }

    validate() {
        if (!this.tmpUser.password) {
            this.errors.password = {id: "Validation.Any.Empty"};
        }
        if (this.tmpUser.confirmPassword !== this.tmpUser.password) {
            this.errors.confirmPassword = {id: "Auth.NewPassword.NotMatch"};
        }
        if (!this.tmpUser.confirmPassword) {
            this.errors.confirmPassword = {id: "Validation.Any.Empty"};
        }

        return !Object.keys(this.errors).length;
    }

    async register() {
        this.loading = true;
        this.errors = {};
        this.globalErrors = [];
        try {
            if (this.validate()) {
                const result = await this.registerInvitation({
                    token: this.token,
                    username: this.username,
                    password: this.tmpUser.password,
                    confirmPassword: this.tmpUser.confirmPassword,
                });
                await this.setToken(result.token);
                await this.setLoginVerified(true);
                await this.$router.push({name: ROUTES.APP._ROOT});
            }
        } catch (e) {
            console.log(e);
            this.errors  = e.errors;
            this.globalErrors = e._global;
        }
        this.loading = false;
    }

    beforeMount() {
        if (!this.username || !this.token) {
            console.log("[!!!] Missing token or email in invitation route. Redirect to / .")
            this.$router.push('/');
        }
    }
}
